::selection {
  background: #000000;
  color: #ffffff;
  text-shadow: none;
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
}
a {
  color: inherit;
}
